<template>
  <section class="detection-bar">
    <!--     <section class="side-bar" :style="`top: ${top}px`">-->

    <div :style="leftStyle" class="dete-title" style="position: sticky; top: 0px;z-index: 1000; width: 260px">
      <img src="@/assets/images/icons/icon-projects.png" style="width: 18px;margin-left:10px;vertical-align: sub;">
      <span class="title">项目监测</span>
      <i v-show="!isTrial" class="icon el-icon-edit-outline" style="cursor:pointer;" @click="editMonitor"></i>
    </div>
    <div class="tree-container" style="position: sticky; top: 40px;z-index: 1000;padding-top: 10px;">
      <div :style="leftStyle" @click="drop">
        <svg style="position: absolute;right: -15px;top: 400px;z-index: 2000;cursor: pointer; background: #67C23A; padding: 5px; border-radius: 5px" t="1712904421450" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3392" width="35" height="35"><path d="M874.666667 800H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h725.333334c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32zM149.333333 224h725.333334c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32zM341.333333 480c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h512c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H341.333333z" fill="#FFFFFF" p-id="3393"></path><path d="M275.2 684.8c6.4 6.4 14.933333 8.533333 23.466667 8.533333s17.066667-2.133333 23.466666-8.533333c12.8-12.8 12.8-32 0-44.8l-128-128 125.866667-125.866667c12.8-12.8 12.8-32 0-44.8-12.8-12.8-32-12.8-44.8 0l-149.333333 149.333334c-12.8 12.8-12.8 32 0 44.8l149.333333 149.333333z" fill="#FFFFFF" p-id="3394"></path></svg>
      </div>
      <div :style="rightStyle" @click="open">
        <svg style="position: absolute;right: -17px;top: 400px;z-index: 2000;cursor: pointer; background: #67C23A; padding: 5px; border-radius: 5px" t="1712904927992" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4035" width="35" height="35"><path d="M874.666667 800H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h725.333334c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32zM149.333333 224h725.333334c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32zM149.333333 544h512c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32H149.333333c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32z" fill="#ffffff" p-id="4036"></path><path d="M748.8 339.2c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8l125.866667 125.866667-125.866667 125.866666c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933333 8.533333 23.466667 8.533334s17.066667-2.133333 23.466666-8.533334l149.333334-149.333333c12.8-12.8 12.8-32 0-44.8l-151.466667-147.2z" fill="#ffffff" p-id="4037"></path></svg>
      </div>
      <div style="position: absolute;right: -15px;z-index: 2000;top: 450px;" v-if="guideToCreateMS2 && isTrial">
        <svg id="guideElement1" @click="openAddMsDialog" style="cursor: pointer; background: #3472c7; padding: 5px; border-radius: 5px" t="1712905022183" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4499" width="35" height="35"><path d="M853.333333 480H544V170.666667c0-17.066667-14.933333-32-32-32s-32 14.933333-32 32v309.333333H170.666667c-17.066667 0-32 14.933333-32 32s14.933333 32 32 32h309.333333V853.333333c0 17.066667 14.933333 32 32 32s32-14.933333 32-32V544H853.333333c17.066667 0 32-14.933333 32-32s-14.933333-32-32-32z" fill="#ffffff" p-id="4500"></path></svg>
      </div>
      <div class="tree-container2" style="overflow-y: auto;height: calc(100vh - 60px);">
        <el-tree
          :style="leftStyle"
          ref="tree"
          class="tree filter-tree"
          icon-class="el-icon-s-data"
          style=""
          :highlight-current="true" node-key="id"
          :data="dataList"
          :default-expanded-keys="expend"
          :default-expand-all="true"
          :expand-on-click-node="false"
          :props="defaultProps"
          @node-click="handleNodeClick"
        >
            <span slot-scope="{ node, data }" class="custom-tree-node">
              <span style="width: 150px;display: block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <i class="el-icon-tickets" v-if="data.pid !== '0'"></i>
                {{ node.label }}
              </span>
            </span>
        </el-tree>
<!--        <div v-if="domain !== 'isTrial" id="fast-add-ms" style="text-align: center; padding: 10px">-->
<!--          <el-button @click="openAddMsDialog" type="primary" size="small" style="width: 100%; font-size: 15px">-->
<!--            添加主题-->
<!--          </el-button>-->
<!--        </div>-->

      </div>
      <div :style="myDialogMask ? { opacity: 1 } : { opacity: 0 }" v-if="myDialogMaskDel && isTrial"
           style="width: 100vw; height: 100vh; background: rgba(0, 0, 0, .2); position:fixed; top: 0; left: 0; z-index: 99999999; transition: all .5s">
        <div :style="showGuide ? { transform: 'scale(1)' } : { transform: 'scale(0)' }"
             style="width: 900px; height: 500px; background: #FFFFFF; position: fixed; top: calc((100vh - 500px) / 2); left: calc((100vw - 900px) / 2); border-radius: 8px; transition: all .5s">
          <div style="display: grid; grid-template-columns: repeat(2, auto);">
            <div v-loading="fastAddMSLoading2" style="width: calc(900px - 363px)">
              <div style="padding: 20px; padding-top: 60px">
                <div style="font-size: 20px; font-weight: bold">选择或填写监测主题，开始您的试用旅途</div>
                <div style="color: gray; margin-top: 10px">选择主题与填写主题只能选择一项</div>

                <div style="margin-top: 40px; font-weight: bold">
                  请选择监测主题
                </div>
                <div style="margin-top: 20px" class="guide-radio">
                  <el-radio-group @input="onChangeSelect" v-model="selectMS" size="small">
                    <el-radio v-for="item in readyToSelectMSList" :label="item.name" border>
                      <template #default>
                        <div style="position: absolute; right: -20px; margin-top: -20px">
                          <div
                            style="color: #EF8D8A; background: #FAE0E0; padding: 5px; border-radius: 10px 10px 10px 0">
                            推荐
                          </div>
                        </div>
                        {{ item.name }}
                      </template>
                    </el-radio>
                  </el-radio-group>
                </div>
                <div style="margin-top: 20px; font-weight: bold">
                  自定义监测主题
                </div>
                <div style="margin-top: 20px" class="guide-input">
                  <el-input size="small" @input="onInputKeyword" v-model="inputKeyword"
                            placeholder="请输入您要监测的关键词"/>
                </div>
                <div style="margin-top: 40px">
                  <el-button @click="confirmGuide" type="primary" size="small" style="border-radius: 50px">开始试用
                  </el-button>
                </div>
              </div>
            </div>
            <div
              style="background-image: url('/guide-right.png'); width: 363px; height: 500px; background-position: center; background-repeat: no-repeat; background-size: auto 100%;"></div>
          </div>
        </div>
        <div :style="showAddMS ? { transform: 'scale(1)' } : { transform: 'scale(0)' }"
             style="width: 900px; height: 500px; background: #FFFFFF; position: fixed; top: calc((100vh - 500px) / 2); left: calc((100vw - 900px) / 2); border-radius: 8px; transition: all .5s">
        <div style="display: grid; grid-template-columns: repeat(2, auto);">
          <div v-loading="fastAddMSLoading" style="width: calc(900px - 363px); overflow-y: auto; height: 500px">
            <div style="padding: 20px; padding-top: 60px">
              <div style="font-size: 20px; font-weight: bold">请创建监测主题</div>
              <div style="color: gray; margin-top: 10px">试用用户只能创建5个主题，且不可修改删除，请谨慎创建</div>

              <div style="margin-top: 40px; font-weight: bold">
                请输入监测关键词
              </div>
              <div style="margin-top: 20px" class="guide-input">
                <div style="margin-bottom: 10px" v-for="(item, index) in tMSArray" :key="index">
                  <div v-if="item.disabled">
                    <el-input style="width: calc(100% - 0px)" size="small" v-model="item.name" :disabled="item.disabled"
                              placeholder="请输入您要监测的关键词"/>
                  </div>
                  <div v-else>
                    <el-input style="width: calc(100% - 40px)" size="small" v-model="item.name"
                              placeholder="请输入您要监测的关键词"/>
                    <svg @click="fastAddMS(item.name)" style="float: right; margin-top: 3px; cursor: pointer" t="1712822482277" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3206" width="25" height="25"><path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m213.333333 554.666667h-170.666666v170.666666a42.666667 42.666667 0 0 1-85.333334 0v-170.666666H298.666667a42.666667 42.666667 0 0 1 0-85.333334h170.666666V298.666667a42.666667 42.666667 0 0 1 85.333334 0v170.666666h170.666666a42.666667 42.666667 0 0 1 0 85.333334z" fill="#5F82CB" p-id="3207"></path></svg>
                  </div>
                </div>
              </div>
              <div style="margin-top: 40px">
                <el-button @click="completeCreation" type="primary" size="small" style="border-radius: 50px">完成创建
                </el-button>
              </div>
            </div>
          </div>
          <div
            style="background-image: url('/guide-right.png'); width: 363px; height: 500px; background-position: center; background-repeat: no-repeat; background-size: auto 100%;"></div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
import { showMonitorSubject, fastAddMS } from '@/api/settings/MonitorSubject'
import { getIsTrial } from '@/utils/auth'
export default {
  props: {
    dropLeft: {
      type: Function,
      default: null
    },
    dropRight: {
      type: Function,
      default: null
    }
  },
  async mounted () {
    this.domain = document.domain

    // window.addEventListener('scroll', this.handleScroll)
    await this.getList()
    if (await getIsTrial()) {
      this.guideToCreateMS2 = true
    }
    if (localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide')) {
      if (localStorage.getItem('guideToCreateMS')) {
        this.guideToCreateMS = localStorage.getItem('guideToCreateMS') === 'true'
      } else {
        this.guideToCreateMS = true
      }
    }

    if (await getIsTrial() && !localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide')) {
      this.myDialogMaskDel = true
      setTimeout(() => {
        this.showGuide = true
        this.myDialogMask = true
      }, 10)
    }
    let currentGuideNumV2 = parseInt(localStorage.getItem('currentGuideNumV2')) || 1
    if (currentGuideNumV2 === 1 && localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide') === 'done') {
      this.$showGuide('添加监测主题', '点击添加按钮可以快速添加监测主题，每个账号有5次添加的机会', 'guideElement' + currentGuideNumV2, 'after')
    }
  },
  data () {
    return {
      isTrial: getIsTrial(),
      myDialogMask: false,
      myDialogMaskDel: false,
      showGuide: false,
      showAddMS: false,
      fastAddMSLoading: false,
      fastAddMSLoading2: false,
      toNewMS: 0,
      selectMS: 0,
      inputKeyword: '',
      tMSArray: [],
      domain: 'huanruan.com',
      guideToCreateMS: false,
      guideToCreateMS2: false,
      currentIndex: 0,
      dataList: [],
      readyToSelectMSList: [],
      top: 130,
      expend: [],
      style: { width: '260px' },
      leftStyle: { display: 'block' },
      rightStyle: { display: 'none' },
      title: { display: 'block' },
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  methods: {
    async fastAddMS (name) {
      if (!name) {
        this.$message({
          message: '关键词不可为空',
          type: 'warning',
          customClass: 'message-zindex'
        })
        return
      }
      this.fastAddMSLoading = true
      const res = await fastAddMS({ name: name })
      if (res.code === 0) {
        this.$message({
          message: '监测主题创建成功',
          type: 'success',
          customClass: 'message-zindex'
        })
        this.toNewMS = res.data
        await this.getList()
        // this.fastAddMSLoading = false
        if (this.tMSArray.length < 5) {
          this.tMSArray.push({
            name: '',
            disabled: false
          })
        }
      } else {
        this.fastAddMSLoading = false
        this.$message({
          message: '创建监测主题时发生了错误',
          type: 'error',
          customClass: 'message-zindex'
        })
      }
    },
    onInputKeyword () {
      this.selectMS = 0
    },
    onChangeSelect (name) {
      this.inputKeyword = name
    },
    openAddMsDialog () {
      this.toNewMS = 0
      if (this.tMSArray.length >= 5) {
        this.$message.warning('当前主题数量已达上限')
        return
      }
      this.tMSArray.push({
        name: '',
        disabled: false
      })
      this.myDialogMaskDel = true
      setTimeout(() => {
        this.showAddMS = true
        this.myDialogMask = true
      }, 10)
    },
    async confirmGuide () {
      if (!this.selectMS && !this.inputKeyword) {
        this.$message({
          message: '必须选择一个主题或自定义一个主题',
          type: 'warning',
          customClass: 'message-zindex'
        })
        return
      }
      localStorage.setItem(localStorage.getItem('ct_account') + 'doneFirstGuide', 'done')
      if (this.selectMS !== 0) {
        for (var i = 0; i < this.readyToSelectMSList.length; i++) {
          if (this.selectMS === this.readyToSelectMSList[i].name) {
            // this.dataList.push(this.readyToSelectMSList[i])
            localStorage.setItem(localStorage.getItem('ct_account') + 'setAlwaysShowMS', this.readyToSelectMSList[i].id)
          }
        }
      } else {
        this.fastAddMSLoading2 = true
        const res = await fastAddMS({ name: this.inputKeyword })
        this.fastAddMSLoading2 = false
        if (res.code === 0) {
          this.$message({
            message: '监测主题创建成功',
            type: 'success',
            customClass: 'message-zindex'
          })
          this.toNewMS = res.data
          var container = document.querySelector('.tree-container2')
          container.scrollTop = container.scrollHeight
          window.scrollTo({
            top: 150,
            behavior: 'smooth' // 平滑滚动效果
          });
          this.$router.push(`/monitor/list/${this.toNewMS}/0`)
          this.$refs.tree.setCurrentKey(this.toNewMS)
        } else {
          this.fastAddMSLoading = false
          this.$message({
            message: '创建监测主题时发生了错误',
            type: 'error',
            customClass: 'message-zindex'
          })
        }
      }
      await this.getList()
      if (localStorage.getItem('guideToCreateMS')) {
        this.guideToCreateMS = localStorage.getItem('guideToCreateMS') === 'true'
      } else {
        this.guideToCreateMS = true
      }
      setTimeout(() => {
        this.showGuide = false
        this.myDialogMask = false
      }, 10)
      setTimeout(() => {
        this.myDialogMaskDel = false
      }, 500)
      this.$showGuide('添加监测主题', '点击添加按钮可以快速添加监测主题，每个账号有5次添加的机会', 'guideElement1', 'after')
    },
    completeCreation () {
      if (!this.tMSArray[this.tMSArray.length - 1].name) {
        this.tMSArray.splice(this.tMSArray.length - 1, 1)
      }
      this.showAddMS = false
      this.myDialogMask = false
      setTimeout(() => {
        this.myDialogMaskDel = false
      }, 500)
      if (this.toNewMS) {
        var container = document.querySelector('.tree-container2')
        container.scrollTop = container.scrollHeight
        window.scrollTo({
          top: 150,
          behavior: 'smooth' // 平滑滚动效果
        });
        this.$router.push(`/monitor/list/${this.toNewMS}/0`)
        this.$refs.tree.setCurrentKey(this.toNewMS)

      }
    },
    iKnow () {
      localStorage.setItem('guideToCreateMS', false)
      this.guideToCreateMS = false
    },
    async getList () {
      this.dataList = []
      this.tMSArray = []
      const res = await showMonitorSubject({ cid: null })
      for (var i = 0; i < res.data.length; i++) {
        if (this.readyToSelectMSList.length === 0 && res.data[i].alwaysShow === 1) {
          this.readyToSelectMSList.push(res.data[i])
        }
      }
      if (getIsTrial() && !localStorage.getItem(localStorage.getItem('ct_account') + 'doneFirstGuide')) {
        return
      }
      if (getIsTrial()) {
        for (var j = 0; j < res.data.length; j++) {
          if (res.data[j].alwaysShow === 1 && localStorage.getItem(localStorage.getItem('ct_account') + 'setAlwaysShowMS') && res.data[j].id + '' === localStorage.getItem(localStorage.getItem('ct_account') + 'setAlwaysShowMS')) {
            this.dataList.push(res.data[j])
          }
          if (res.data[j].alwaysShow === 0) {
            this.dataList.push(res.data[j])
            this.tMSArray.push({
              name: res.data[j].name,
              id: res.data[j].id,
              disabled: true
            })
          }
        }
        this.fastAddMSLoading = false
      } else {
        this.dataList = res.data
      }
      this.$nextTick(() => {
        if (this.$route.path.indexOf('monitor/list') !== -1) {
          if (this.$route.params.nodeId && this.$route.params.nodeId !== '0') {
            this.$refs.tree.setCurrentKey(this.$route.params.nodeId)
            this.expend = [this.$route.params.nodeId]
          } else if (this.$route.params.nodeId == '0') {
            this.$refs.tree.setCurrentKey(this.$route.params.id)
            this.expend = [this.$route.params.id]
          } else {
            this.$refs.tree.setCurrentKey(this.dataList[0].id)
            this.$router.push('/monitor/list/' + this.dataList[0].id + '/0')
          }
        } else if (this.$route.path.indexOf('monitor/add') !== -1) {
          if (this.$route.params.nodeId && this.$route.params.nodeId !== '0') {
            this.$refs.tree.setCurrentKey(this.$route.params.nodeId)
            this.expend = [this.$route.params.nodeId]
          }
        }

        sessionStorage.setItem(localStorage.getItem('ct_account') + 'currentMonitor', JSON.stringify(this.$refs.tree.getCurrentNode()))
      })
    },
    drop () {
      this.dropLeft()
      this.style.width = 0
      this.title.display = 'none'
      this.leftStyle.display = 'none'
      this.rightStyle.display = 'block'
      this.guideToCreateMS = false
      this.guideToCreateMS2 = false
    },
    open () {
      this.dropRight()
      this.style.width = '260px'
      this.title.display = 'block'
      this.leftStyle.display = 'block'
      this.rightStyle.display = 'none'
      if (localStorage.getItem('guideToCreateMS')) {
        this.guideToCreateMS = localStorage.getItem('guideToCreateMS') === 'true'
      } else {
        this.guideToCreateMS = true
      }
      this.guideToCreateMS2 = true
    },
    handleNodeClick (data) {
      if (data.subjectId !== '0') {
        this.$router.push('/monitor/list/' + data.subjectId + '/' + data.id)
      } else {
        this.$router.push('/monitor/list/' + data.id + '/0')
      }
      this.currentIndex = 1
      sessionStorage.setItem(localStorage.getItem('ct_account') + 'currentMonitor', JSON.stringify(this.$refs.tree.getCurrentNode()))
    },
    handleScroll () { // 改变元素#searchBar的top值
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= 100) {
        this.top = 30
      } else if (scrollTop < 100) {
        this.top = 130 - scrollTop
      }
    },
    editMonitor () {
      this.$router.push('/settings/theme')
    }
  },
  // computed: mapGetters(['navList', 'navMap']),
  computed: {
    // 监听数据
    getnewValue () {
      return this.$route.path
    }
  },
  watch: {
    //  监听改变事件
    getnewValue: {
      handler (newValue, oldValue) {
        // this.getList()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/index';

.dete-title {
  background-color: #3472c7;
  height: 40px;
  line-height: 40px;
  color: #fff;

  .title {
    margin-left: 5px;
  }

  .icon {
    float: right;
    margin: 12px;
  }
}

.detection-bar {
  width: 200px;
  height: calc(100% - 30px);
  background-color: #FFFFFF;
  z-index: 2000;

  .guide-radio {
    .el-radio.is-bordered {
      border-radius: 50px;
    }

    .el-radio.is-bordered.is-checked {
      border-color: #3472c7;
      background: #3472c7;
    }

    .el-radio__input.is-checked + .el-radio__label {
      color: #FFFFFF;
    }

    .el-radio.is-bordered + .el-radio.is-bordered {
      margin-left: 0;
    }

    .el-radio {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  .guide-input {
    .el-input__inner {
      border-radius: 50px;
    }
  }
}

/* 树形结构节点添加连线 */
.tree .el-tree-node {
  position: relative;
  padding-left: 16px;
}

.tree .el-tree-node__content {
  padding-left: 0 !important;
  height: 30px;
}

.tree .el-tree-node__children {
  padding-left: 16px;
}

.tree .el-tree-node :last-child:before {
  height: 38px;
}

.tree .el-tree > .el-tree-node:before {
  border-left: none;
}

.tree-container .el-tree > .el-tree-node:after {
  border-top: none;
}

.tree .el-tree-node__children .el-tree-node:before {
  content: '';
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree .el-tree-node:after {
  content: '';
  left: -4px;
  position: absolute;
  right: auto;
  border-width: 1px;
}

.tree .el-tree-node__expand-icon.is-leaf {
  display: none;
}

.tree .el-tree-node:before {
  border-left: 1px dashed #dddddd;
  bottom: 0px;
  height: 100%;
  top: -26px;
  width: 1px;
}

.tree .el-tree-node:after {
  border-top: 1px dashed #dddddd;
  height: 20px;
  top: 12px;
  width: 24px;
}

.tree .custom-tree-node {
  font-size: 15px;
  padding-left: 10px;
}

.el-tree-node:hover {
  background-color: #fafafa;
}

.el-tree-node.is-current > .el-tree-node__content {
  background-color: #f0f7ff;
  color: #3472c7;
}

.side-bar {
  // width: 200px;
  // height: $contentHeight;
  height: calc(100% - 70px);
  background: #fff;
  // border-radius: 8px;
  display: inline-block;
  padding: 10px 0;
  position: relative;

  .drop {
    position: absolute;
    top: 240px;
    z-index: 9;
    cursor: pointer;

    img {
      width: 23px;
    }

    &.dropLeft {
      right: 0;
    }

    &.dropRight {
      left: -20px;
    }
  }

  .activeClass {
    color: #3472c7;
    background-color: #f0f7ff;
  }

  .tree-container {
    height: 100%;
  }

  .el-tree {
    height: 100%;
    overflow-y: scroll;
    // padding-left: 15px;
  }

  .el-tree::-webkit-scrollbar {
    width: 0;
  }

  .el-tree::-webkit-scrollbar-track {
    background-color: #f8f8f8;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  .el-tree::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  .menuList {
    width: 100%;
    cursor: pointer;
    line-height: 40px;
    padding: 0;
    height: 40px;
    font-size: 14px;
    color: #303133;
    position: relative;

    .monitor {
      padding: 0 20px;
      // font-size: 18px;
    }

    .el-icon-edit {
      position: absolute;
      top: 12px;
      right: 20px;
      color: #909399;
      font-size: 17px;
    }

    .sortName {
      padding-left: 40px;
    }

    .sortName:hover, .monitor:hover {
      background-color: #fafafa;
    }
  }
}

</style>
<style>
.tree-container2::-webkit-scrollbar {
  display: none; /* 隐藏滚动条（仅适用于webkit内核的浏览器，如Chrome和Safari） */
}

/* Firefox浏览器 */
.tree-container2 {
  scroll-behavior: smooth;
  scrollbar-width: none; /* 隐藏滚动条 */
}
/*.arrow-box-tip {*/
/*  position: absolute;*/
/*  background-color: rgba(52, 114, 199, 1);*/
/*  !*backdrop-filter: blur(15px);*!*/
/*  width: 320px;*/
/*  left: 290px;*/
/*  top: 480px;*/
/*  padding: 20px 10px 10px 10px;*/
/*  color: #FFF;*/
/*  border-radius: 15px;*/
/*  z-index: 8;*/
/*  border-color: transparent transparent #FFF transparent;*/
/*  overflow: visible; !* 设置为可见，以便箭头超出父元素 *!*/
/*  margin-top: -75px;*/
/*}*/

/*.arrow-box-tip::after {*/
/*  content: "";*/
/*  width: 0;*/
/*  height: 0;*/
/*  border-style: solid;*/
/*  border-width: 10px;*/
/*  border-color: transparent transparent transparent rgba(52, 114, 199, 1);*/
/*  !*backdrop-filter: blur(15px);*!*/
/*  position: absolute;*/
/*  z-index: 1000;*/
/*  top: 53px; !* 调整箭头位置 *!*/
/*  left: -20px; !* 调整箭头位置 *!*/
/*  transform: rotate(180deg);*/
/*}*/

.message-zindex {
  z-index: 2002 !important
}
</style>
