<template>
  <section class="detection-wrap">
    <detectionBar style="transition: all .5s" :style="style" class="detectBar" :dropLeft="dropFun" :dropRight="cancleDrop" ref="detectbar" v-show="$route.path.indexOf('monitor/detail')===-1"/>
    <section class="deteContent">
      <router-view />
    </section>
  </section>
</template>

<script>
import DetectionBar from '@/components/detectionBar'
export default {
  components: {
    DetectionBar
  },
  data () {
    return {
      style:{
        width:null
      }
    }
  },
  methods:{
    dropFun(){
      this.style.width=0;
    },
    cancleDrop(){
      this.style.width='260px';
    }
  }
}
</script>
<style lang="scss">
@import "@/styles/index";

.detection-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  .deteContent{
    width: 100%;
    min-width: calc(100% - 280px);
    height: fit-content;
    margin-left: 20px;
    .el-dialog{
      .quanxuan {
        .el-input--suffix .el-input__inner {
          padding-left: 40px;
        }
      }
    }
  }
  .detectBar{
    width: 260px;
  }
  .detection-content {
    width: 100%;
    #div1,
    #div2 {
      width: 100%;
      border-radius: 3px;
      // margin-left: 30px;
      margin-bottom: 30px;
      border: 1px #eceaea solid;
      background: #ffffff;
      box-shadow: 1px 1px 3px 1px rgba(212, 211, 211, 0.2);
    }

    #div1 {
      padding: 20px 25px;
      .dateContainer {
        color: #000000;
        font-size: 15px;
      }
      .btn-wrapper{
        .el-input--mini .el-input__inner{
          height: 31px;
          line-height: 31px;
        }
      }
      .beginTime,
      .endTime {
        font-size: 12px;
      }

      .el-date-editor {
        position: absolute;
        left: 30%;
        bottom: -33px;
        z-index: 1;
      }
    }

    #div2 {
      position: relative;
    }

    #div3 {
      padding: 30px 30px 30px 30px;

      .batchBtn {
        position: relative;
      }

      #div4 {
        display: flex;
        float: right;
        gap: 5px;
      }

      .el-dropdown {
        margin-right: 10px;
      }

      #div5 {
        border: 1px #eceaea solid;
      }

      .batchMenu {
        width: 125px;
        // height: 206px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 30px;
        left: 0px;
        z-index: abs($number: 999);

        .menuUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: left;
            padding-left: 15px;
            color: rgb(14, 13, 13);
          }

          li:hover {
            background-color: #3372C7;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }

      .emotionMenu {
        width: 60px;
        height: 100px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 0px;
        left: 125px;
        z-index: abs($number: 999);

        .emotionUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: #3372C7;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .forwardPanel {
        width: 100px;
        height: 70px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 140px;
        left: 125px;
        z-index: abs($number: 999);

        .batchForward {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: #3372C7;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }

      .sourceMenu {
        width: 60px;
        height: 350px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 0px;
        left: 125px;
        z-index: abs($number: 999);

        .emotionUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: #3372C7;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }

      .openLabel {
        position: relative;

        .mark-wrap {
          width: 300px;
          height: 330px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
          position: absolute;
          top: -17px;
          left: 101%;
          padding: 15px;

          .table-wrap {
            height: 200px;
            border: 1px solid #979797;
            overflow-x: auto;
            overflow-y: auto;

            .mark-table {
              display: flex;

              .table-col {
                padding: 0;
                border-right: 1px solid #979797;
                flex-grow: 1;
                flex-shrink: 0;

                &:last-child {
                  border-right: none;
                }

                .mark-header {
                  border-bottom: 1px solid #979797;
                }

                li {
                  text-align: left;
                  padding: 0 10px;
                  // width: 100px;
                }
              }
            }
          }

          .table-bottom {
            padding-top: 5px;
          }
        }
      }
    }

    .el-table {
      border-radius: 10px;
      margin-left: 20px;
      overflow: visible;

      .el-table__body-wrapper {
        overflow: visible;
      }

      th.gutter {
        display: table-cell !important;
      }

      &.el-table th>.cell {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .el-table__row:last-child .oneOperationPanel,
      .el-table__row:nth-last-child(2) .oneOperationPanel{
        bottom: 0;
      }
      .caozuo {
        width: 25px;
        height: 25px;
      }

      .oneOperationPanel {
        width: 125px;
        // height: 250px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        right: 0px;
        z-index: abs($number: 999);

        .oneOperationUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: left;
            padding-left: 15px;
            color: rgb(14, 13, 13);
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }

      .showOneEmotionPanel {
        width: 60px;
        height: 103px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 0px;
        right: 125px;
        z-index: abs($number: 999);

        .oneEmotionUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }
      .showNode {
        width: 200px;
        // height: 200px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        top: 70px;
        right: 125px;
        z-index: abs($number: 999);

        .oneEmotionUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: center;
            padding-left: 0px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }
      .oneMarkPanel {
        position: relative;

        .mark-wrap-one {
          width: 300px;
          height: 330px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 2px 2px 6px 0px rgba(163, 163, 163, 0.5);
          position: absolute;
          top: -17px;
          right: 125px;
          padding: 15px;
          border: 1px #e6e0e0 solid;

          .table-wrap-one {
            height: 200px;
            border: 1px solid #979797;
            overflow-x: auto;
            overflow-y: auto;

            .mark-table-one {
              display: flex;

              .table-col {
                padding: 0;
                border-right: 1px solid #979797;
                flex-grow: 1;
                flex-shrink: 0;

                &:last-child {
                  border-right: none;
                }

                .mark-header {
                  border-bottom: 1px solid #979797;
                }

                li {
                  text-align: left;
                  padding: 0 10px;
                  // width: 100px;
                }
              }
            }
          }

          .table-bottom-one {
            padding-top: 5px;
          }
        }
      }

      .forwardPanelOne {
        width: 100px;
        height: 70px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        // top: 207px;
        bottom: 70px;
        right: 125px;
        z-index: abs($number: 999);

        .oneForwardUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: left;
            padding-left: 13px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }
      .newsGroupPanelOne {
        width: 110px;
        height: 38px;
        position: absolute;
        background-color: #ffffff;
        box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
        // top: 207px;
        bottom: 0;
        right: 125px;
        z-index: abs($number: 999);

        .oneNewsGroupUl {
          padding-left: 0px;
          line-height: 2.5;
          font-size: 14px;

          li {
            text-align: left;
            padding-left: 13px;
          }

          li:hover {
            background-color: rgb(203, 206, 209);
            cursor: pointer;
          }
        }
      }
      .bottomEllipsis {
        .oneOperationPanel {
          // bottom: 32px;
        }
      }
    }

    .el-form {
      // padding: 30px 30px 30px 30px;
      display: inline-block;
      vertical-align: top;
      .el-input--suffix .el-input__inner {
        height: 30px;
      }
    }

    /* .el-form-item {
      margin-bottom: 0px;
      height: 43px;
      // background-color: #f7f7f7;
      // border: 0.4px #f0eeee solid;
      &:not(:last-child){
        border-bottom: none;
      }
      &:last-child{
        .el-form-item__content {
          line-height: 41px;
        }
      } */
      .el-form-item__content {
        padding-left: 15px;
        // background-color: #ffffff;
        line-height: 42px;
        .el-checkbox {
          margin-right: 15px;
        }

        .el-checkbox-group {
          margin-bottom: -3px;
        }
      }

      .el-radio-group {
        margin-bottom: 3px;

        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          margin-left: 20px;
        }

        .el-date-editor--daterange {
          height: 30px !important;
        }
      }
      .el-radio.is-bordered.is-checked:not(.is-disabled){
        border: 1px solid #3472c7;
      }
      .el-radio__input.is-checked:not(.is-disabled) + .el-radio__label{
        color: #3472c7;
      }
      .el-radio--mini.is-bordered {
        padding: 5px 10px 0px 0px;
        margin-right: 1px;
      }

      #emotion {
        padding: 3px 1px 1px 0px;
      }

      #emotion .el-checkbox.is-bordered.el-checkbox--mini {
        padding: 4px 10px 0px 0px;
      }

      #emotion .el-checkbox__input {
        display: none;
      }

      .el-button {
        height: 30px;
        padding-top: 8px;
      }

      /* #searchBtn {
        margin-left: 12px;
      } */

      .el-date-editor .el-range-separator {
        padding-bottom: 11px;
      }

    }

    .el-radio__input {
      display: none;
    }

    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: -50px;
      left: 20px;
      right: 20px;

      .count {
        line-height: 32px;
      }
    }
  // }

  .dialog_form {
    text-align: left;
  }
}

.prompt {
  height: 28px;
  display: flex;
  align-items: center;

  .el-icon-warning {
    font-size: 28px;
    color: #FF8B35;
    margin-right: 12px;
  }
}

.el-tree-node__label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
